@use "mixins/opacity";
@use "mixins/reset-text";
@use "variables";

//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  position: absolute;
  z-index: variables.$zindex-tooltip;
  display: block;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text.reset-text;
  font-size: variables.$font-size-small;

  @include opacity.opacity(0);

  &.in     { @include opacity.opacity(variables.$tooltip-opacity); }
  &.top    { margin-top:  -3px; padding: variables.$tooltip-arrow-width 0; }
  &.right  { margin-left:  3px; padding: 0 variables.$tooltip-arrow-width; }
  &.bottom { margin-top:   3px; padding: variables.$tooltip-arrow-width 0; }
  &.left   { margin-left: -3px; padding: 0 variables.$tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: variables.$tooltip-max-width;
  padding: 3px 8px;
  color: variables.$tooltip-color;
  text-align: center;
  background-color: variables.$tooltip-bg;
  border-radius: variables.$border-radius-base;
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-top-color: variables.$tooltip-arrow-color;
  }
  &.top-left .tooltip-arrow {
    bottom: 0;
    right: variables.$tooltip-arrow-width;
    margin-bottom: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-top-color: variables.$tooltip-arrow-color;
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: variables.$tooltip-arrow-width;
    margin-bottom: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-top-color: variables.$tooltip-arrow-color;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width variables.$tooltip-arrow-width variables.$tooltip-arrow-width 0;
    border-right-color: variables.$tooltip-arrow-color;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: variables.$tooltip-arrow-width 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-left-color: variables.$tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -(variables.$tooltip-arrow-width);
    border-width: 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-bottom-color: variables.$tooltip-arrow-color;
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: variables.$tooltip-arrow-width;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-bottom-color: variables.$tooltip-arrow-color;
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: variables.$tooltip-arrow-width;
    margin-top: -(variables.$tooltip-arrow-width);
    border-width: 0 variables.$tooltip-arrow-width variables.$tooltip-arrow-width;
    border-bottom-color: variables.$tooltip-arrow-color;
  }
}
